import { dobObj } from '@/src/components/Steps/utils/schemas/contacts'
import * as yup from 'yup'

const EMAIL_REGX =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

const emailObj = {
  email: yup
    .string()
    .required('validation.email.required')
    .matches(EMAIL_REGX, 'onboarding:string.valid.email')
    .email()
    .transform((value) => {
      return value.replace(/\s/g, '')
    }),
  apiErrors: yup.string(),
}

const passObj = {
  password: yup.string().required('string.password.required'),
  apiErrors: yup.string().nullable(),
}

export const forgotPasswordSchema = (isDobRequired: boolean) => {
  const _dobObj = dobObj
  if (isDobRequired) {
    _dobObj.date.required()
    _dobObj.year.required()
    _dobObj.month.required()
  }

  return yup.object({
    ...emailObj,
    ..._dobObj,
    apiErrors: yup.string(),
  })
}
export const loginPasswordSchema = () =>
  yup.object({
    ...emailObj,
    ...passObj,
    ...{
      receiveEmail: yup.boolean().nullable().notRequired(),
      receiveSms: yup.boolean().nullable().notRequired(),
      policyChecked: yup.boolean().nullable().notRequired(),
      licenseChecked: yup.boolean().nullable().notRequired(),
      tcVersion: yup.string().nullable().notRequired(),
    },
  })

export const resetPasswordSchema = () =>
  yup
    .object({
      password: yup.string().required('string.password.required'),
      confirmPassword: yup
        .string()
        .oneOf([yup.ref('password')], 'string.passwords.match')
        .required('string.confirm.password.required'),
      activationCode: yup.string().required('string.activationCode.required'),
    })
    .required()
