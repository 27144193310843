import PageTitle from '@/src/components/PageTitle'
import Paragraph from '@/src/components/Typography/Paragraph'
import cn from 'classnames'
import { ReactElement } from 'react'

type ContainerMyAccountHeadingType = {
  headerTxt: string
  description?: string | ReactElement
  classNameWrapper?: string
  className?: string
}

const ContainerMyAccountHeading = ({
  headerTxt,
  description,
  classNameWrapper,
  className,
}: ContainerMyAccountHeadingType) => {
  return (
    <div className={cn(classNameWrapper, 'flex flex-col gap-2')}>
      <PageTitle
        className={className}
        hideBackButton
        noMargin
        defaultMarginBottom={false}
      >
        {headerTxt}
      </PageTitle>

      {description && (
        <Paragraph className='mb-0 text-center text-lg opacity-70'>
          {description}
        </Paragraph>
      )}
    </div>
  )
}

export default ContainerMyAccountHeading
