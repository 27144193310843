import { api } from '@/src/services'
import {
  LoginFormInput,
  LoginType,
  SignInFormInput,
} from '@/src/services/types/auth.tsx'
import { getFormData } from '@/src/services/utils/index.tsx'

const authApi = api.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<LoginType, LoginFormInput>({
      query: ({
        t,
        licenseChecked = false,
        policyChecked = false,
        tcVersion,
      }) => ({
        url: 'login',
        method: 'POST',
        body: getFormData({
          t,
          tcVersion,
          licenseChecked: licenseChecked.toString(),
          policyChecked: policyChecked.toString(),
        }),
      }),
      invalidatesTags: [
        'Balance',
        'User',
        'PaymentMethods',
        'Vouchers',
        'Token',
        'Limits',
      ],
    }),
    logout: builder.mutation<LoginType, void>({
      query: () => ({
        url: 'logout',
        method: 'POST',
      }),
      invalidatesTags: ['Balance', 'User'],
    }),
    signin: builder.mutation<LoginType, SignInFormInput>({
      query: (formInput) => {
        return {
          url: `login`,
          method: 'POST',
          body: getFormData(formInput || {}),
        }
      },
      invalidatesTags: [
        'Balance',
        'User',
        'PaymentMethods',
        'Vouchers',
        'Token',
        'Limits',
      ],
    }),
  }),
  overrideExisting: process.env.NODE_ENV === 'development',
})

export const { useLoginMutation, useLogoutMutation, useSigninMutation } =
  authApi

//SSR endpoints
export const { login, logout, signin } = authApi.endpoints

export default authApi
