'use client'
import { RoundedButton } from '@/src/components/Button'
import Modal from '@/src/components/Modal/Modal'
import TextLink from '@/src/components/Typography/TextLink'
import { closeModal } from '@/src/store/slices/modalSlice'
import { SerializedPrismicRichText } from '@/src/utils/prismic'
import { PrismicDocument, RichTextField } from '@prismicio/client'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

type AcceptTermsModalProps = {
  termsAndConditions: PrismicDocument
  onClose: () => void
  onAccept: () => void
}
const AcceptTermsModal = ({
  termsAndConditions,
  onAccept,
  onClose,
}: AcceptTermsModalProps) => {
  const dispatch = useDispatch()
  const body = termsAndConditions?.data?.body as RichTextField
  const { t } = useTranslation(['common', 'compliance'])

  return (
    <Modal
      onClose={() => dispatch(closeModal('new-player'))}
      targetModal={'accept-terms'}
      allowClickOutside={false}
      allowClose={false}
      heading={t('accept-new-terms', { ns: 'compliance' })}
      scrollable
    >
      <div className='mt-2'>
        <div className='h-[40vh] w-full max-w-xl overflow-y-auto overflow-x-hidden rounded-lg bg-primary-dark p-6 sm:h-[50vh]'>
          <SerializedPrismicRichText field={body} />
        </div>
        <div className='mt-8 flex w-full flex-col items-center gap-4 text-center'>
          <RoundedButton fullWidth size='large' onClick={onAccept}>
            {t('accept')}
          </RoundedButton>
          <TextLink onClick={onClose}>{t('logout')}</TextLink>
        </div>
      </div>
    </Modal>
  )
}

export default AcceptTermsModal
