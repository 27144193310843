import { ageRestriction } from '@/src/components/Steps/utils/ErrorHandling/ContactsForm'
import { FieldError } from 'react-hook-form'
import * as yup from 'yup'

export type UserFormInputErrorsType = {
  firstName?: FieldError | undefined
  middleName?: FieldError | undefined
  lastName?: FieldError | undefined
  year?: FieldError | undefined
  month?: FieldError | undefined
  date?: FieldError | undefined
  ageVerified?: FieldError | undefined
  apiErrors?: FieldError | undefined
}

export const dobObj = {
  year: yup.number().typeError('onboarding:string.date.of.birth.required'),
  month: yup.number().typeError('onboarding:string.date.of.birth.required'),
  date: yup.number().typeError('onboarding:string.date.of.birth.required'),
}

export const contactsSchema = () =>
  yup
    .object({
      firstName: yup
        .string()
        .required('string.first.name.required')
        .max(45, {
          key: 'string.first.name.max',
          values: { max: 45 },
        }),
      middleName: yup.string().max(45, {
        key: 'string.middle.name.max',
        values: { max: 45 },
      }),
      lastName: yup
        .string()
        .required('string.last.name.required')
        .max(45, {
          key: 'string.last.name.max',
          values: { max: 45 },
        }),
      ...dobObj,
      ageVerified: yup.boolean().required('boolean.age.verified.required'),
      apiErrors: yup.string(),
    })
    .test((info, ctx) => {
      if (info.date && info.month && info.year) {
        if (ageRestriction(`${info.year}/${info.month}/${info.date}`)) {
          return ctx.createError({
            path: 'date',
            message: 'date-of-birt-required-19-over',
          })
        }
      }

      if (info.ageVerified) {
        return true
      }

      return ctx.createError({
        path: 'ageVerified',
        message: 'boolean.age.verified.required',
      })
    })
    .required()
