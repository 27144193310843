'use client'
import Loading from '@/src/components/Loading'
import useAuthGuard from '@/src/hooks/useAuthGuard'

type AuthGuardProps = {
  children: React.ReactNode
  redirectTo?: string
  redirectIfFound?: 'loggedIn' | 'loggedOut'
}

const AuthGuard = ({
  children,
  redirectIfFound = 'loggedOut',
  redirectTo = '/',
}: AuthGuardProps) => {
  const { isLoggedIn } = useAuthGuard({ redirectIfFound, redirectTo })
  return isLoggedIn ? (
    <>{children}</>
  ) : (
    <Loading loading={true} backgroudType='stars' position='absolute' />
  )
}

export default AuthGuard
