import ServerError from '@/src/const/server-errors'
import gamesApi from '@/src/services/games'
import {
  gtmDeposit,
  gtmLogin,
} from '@/src/services/gtm_factory.ts'
import { getPlayer } from '@/src/services/player'
import { LoginType, PlayerType, StatusType } from '@/src/services/types'
import { LoginFormInput } from '@/src/services/types/auth.tsx'
import { isLoggedIn } from '@/src/store/slices/authSlice'
import { makeStore } from '@/src/store/store'
import {
  clearAuthData,
  clearAuthStorage,
  clearAuthUrl,
  getLastVisitedLobby,
  getReturnToPage,
} from '@/src/utils/localStorage'
import { AppRouterInstance } from 'next/dist/shared/lib/app-router-context.shared-runtime'
import { Dispatch, SetStateAction } from 'react'

type LoginReturnType = 'ok' | 'new-user' | 'accept-terms' | 'error'
export const handleLogin = (
  loginData: LoginType | StatusType,
): LoginReturnType => {
  // captureMessage(`Handle Login with loginData: ${loginData}`, 'fatal')

  if (
    loginData.errorCode === ServerError.EmailNotFilledException ||
    loginData.errorCode === ServerError.LicenseNotAcceptedException
  ) {
    return 'new-user'
  } else if (
    loginData.errorCode === ServerError.LicenseAndTermsNotAcceptedException ||
    loginData.errorCode === ServerError.TermsNotAcceptedException
  ) {
    return 'accept-terms'
  } else if (loginData.errorCode) {
    return 'error'
  }

  return 'ok'
}

export async function continueToSite(
  router: AppRouterInstance,
  actionType: string | null,
  provider: 'trustly' | 'swish' | 'test',
  limitExceeded: boolean = false,
) {
  const store = makeStore()
  store.dispatch(isLoggedIn(true))
  store.dispatch(gamesApi.util.invalidateTags(['RecentGame']))

  const player = await getPlayerInfo()

  if (!player) {
    // Send to generic error page if no player
    router.push('/error/100')
    return
  }

  if (actionType === 'depositLoginAction' && provider === 'trustly') {
    gtmLogin()
    gtmDeposit()
  }

  if (actionType === 'depositLoginAction' && provider === 'swish') {
    clearAuthData()
    clearAuthUrl()
    return router.replace('/pnp/swish')
  }

  clearAuthStorage()

  if (!player?.email) {
    router.replace('/missing-info')
  } else if (limitExceeded) {
    return
  } else {
    if (actionType === 'depositLoginAction') {
      router.push('/pnp/deposit/success')
    } else {
      const lvl = getLastVisitedLobby()
      const lvpRESUME = getReturnToPage()
      if (lvpRESUME) {
        router.push(lvpRESUME)
      } else {
        router.push(lvl)
      }
    }
  }
}


const getPlayerInfo = async (): Promise<PlayerType | undefined> => {
  const { data: player } = await makeStore().dispatch(getPlayer.initiate())
  return player
}

type LoginFunction = (arg: LoginFormInput) => void

const acceptTerms = ({
  loginData,
  login,
  setNeedsAcceptTerms,
  tcVersion,
}: {
  loginData: LoginType | undefined
  login: LoginFunction
  setNeedsAcceptTerms: Dispatch<SetStateAction<boolean>>
  tcVersion: string
}): void => {
  if (loginData?.error) {
    login({
      t: loginData?.error,
      tcVersion,
      licenseChecked: true,
      policyChecked: true,
    })
    setNeedsAcceptTerms(false)
  }
}

const declineTerms = (router: AppRouterInstance): void => {
  router.replace('/logout')
}

export { acceptTerms, declineTerms }
