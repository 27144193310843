import ServerError from '@/src/const/server-errors'
import { TFunction, TranslationsArr } from 'i18next'
type ValueOf<T> = T[keyof T]
function getServerErrorText(
  t: TFunction<TranslationsArr>,
  error?: ValueOf<typeof ServerError>,
  replace?: Record<string, unknown>,
): string {
  if (!error) {
    return 'Unexpected error'
  }
  return t(`err.code.${error}`, {
    defaultValue: `err.code.${error}`,
    ns: 'server-errors',
    ...replace,
  })
}

export default getServerErrorText
