import styles from '@/src/components/Divider/Divider.module.scss'
import cn from 'classnames'

type DividerType = {
  className?: string
}

function Divider({ className }: DividerType) {
  return (
    <hr
      className={cn(className, styles.divider, {
        'my-6': !className || className.indexOf('my-') === -1,
      })}
    />
  )
}

export default Divider
