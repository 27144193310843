import styles from '@/src/components/Button/Back/Back.module.scss'
import { RoundedButton } from '@/src/components/Button/index'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'
import { SlArrowLeft } from 'react-icons/sl'

type BackButtonType = {
  onClick?: () => void
}

const BackBtn = ({ onClick }: BackButtonType) => {
  const { t } = useTranslation('common')
  return (
    <span className='w-36'>
      <RoundedButton
        type='secondary'
        onClick={onClick}
        className='relative w-36'
      >
        <SlArrowLeft
          className={cn(styles.backButtonCaret, 'absolute left-4')}
        />
        {t('go-back-full', { ns: 'common' })}
      </RoundedButton>
    </span>
  )
}

export default BackBtn
