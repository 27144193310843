'use client'
import Loading from '@/src/components/Loading'
import useRouter from '@/src/hooks/Router/useRouter'
import useAuthGuard from '@/src/hooks/useAuthGuard'
import { useGetSettingsQuery } from '@/src/services/config'
import { PropsWithChildren, useEffect } from 'react'

export default function LoginGuard({ children }: PropsWithChildren) {
  const { isLoggedIn } = useAuthGuard()
  const router = useRouter()

  const { data: settings } = useGetSettingsQuery()

  const isTestUser = settings?.testUser === 'true'

  useEffect(() => {
    if (isLoggedIn) {
      router.replace('/')
    }
  })

  useEffect(() => {
    if (settings && !isTestUser) {
      router.replace('/pnp/deposit')
    }
  }, [isTestUser, settings, router])

  return isLoggedIn === undefined || isLoggedIn || !isTestUser ? (
    <Loading loading />
  ) : (
    children
  )
}
