'use client'
import BackBtn from '@/src/components/Button/Back'
import RoundedButton from '@/src/components/Button/RoundedButton'
import ContainerMyAccountHeading from '@/src/components/ContainerMyAccount/Heading'
import Divider from '@/src/components/Divider'
import { Input } from '@/src/components/FormElements'
import { loginPasswordSchema } from '@/src/components/Login/utils/Schemas/Login.ts'
import AcceptTermsModal from '@/src/components/Modal/AcceptTermsModal'
import { CriticalErrors } from '@/src/const/server-errors'
import useRouter from '@/src/hooks/Router/useRouter'
import useAuthGuard from '@/src/hooks/useAuthGuard.tsx'
import { useSigninMutation } from '@/src/services/auth'
import { SignInFormInput } from '@/src/services/types/auth'
import { closeModal, showModal } from '@/src/store/slices/modalSlice'
import { continueToSite } from '@/src/utils/auth/loginHelper'
import getServerErrorText from '@/src/utils/server-errors'
import { yupResolver } from '@hookform/resolvers/yup'
import { PrismicDocument } from '@prismicio/client'
import cn from 'classnames'
import { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

type LoginFormProps = {
  termsAndConditions: PrismicDocument
}

function LoginForm({ termsAndConditions }: LoginFormProps) {
  const { isLoggedIn } = useAuthGuard()
  const router = useRouter()

  useEffect(() => {
    if (isLoggedIn) {
      continueToSite(router, null, 'test')
    }
  }, [isLoggedIn, router])

  const { t } = useTranslation([
    'login',
    'my-account',
    'onboarding',
    'server-errors',
  ])
  const dispatch = useDispatch()

  const methods = useForm({
    resolver: yupResolver(loginPasswordSchema()),
  })
  const {
    register,
    setValue,
    handleSubmit,
    clearErrors,
    formState: { isSubmitting, errors },
  } = methods

  // Mutations
  const [signin, { data: responseSignIn }] = useSigninMutation()

  // Sign in response
  useEffect(() => {
    const errorCode = responseSignIn?.errorCode

    if (errorCode) {
      // We don't display critical errors, since user will be redirected
      if (CriticalErrors.includes(errorCode)) return

      methods.setError('password', {
        message: getServerErrorText(t, errorCode, {
          ...(responseSignIn?.params && responseSignIn.params),
        }),
      })

      if (errorCode === 155) dispatch(showModal('accept-terms'))
    }
  }, [responseSignIn, t, router, methods, dispatch])

  const onSubmit = async (props: SignInFormInput) => await signin(props)

  const closePasswordStep = () => {
    clearErrors()
    router.push('/')
  }

  const handleAcceptTerms = async () => {
    // policyChecked & licenseChecked
    setValue('licenseChecked', true)
    setValue('policyChecked', true)
    setValue('tcVersion', termsAndConditions?.data?.version)
    await handleSubmit(onSubmit)()
    dispatch(closeModal('accept-terms'))
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ContainerMyAccountHeading
          classNameWrapper='mb-10'
          headerTxt={t('enter-password-title')}
        />
        <div className='flex flex-col gap-6'>
          <Input
            {...register('email')}
            type={'email'}
            className={cn('mt-0 text-left')}
            placeholder='Email address'
            theme='light'
            autoComplete='email'
            data-testid='login-email'
            error={errors.email}
            autoFocus
          />
          <Input
            {...register('password')}
            type={'password'}
            className={'text-left'}
            placeholder={t('login.password-placeholder')}
            data-testid='login-password'
            disabled={isSubmitting}
            error={errors.password}
          />

          <Input {...register('licenseChecked')} type='checkbox' hidden />
          <Input {...register('policyChecked')} type='checkbox' hidden />
          <Input {...register('tcVersion')} type='hidden' hidden />

          <div className='mx-auto flex flex-col'>
            <RoundedButton
              size='large'
              className='mb-0 w-full'
              disabled={isSubmitting}
              isSubmit={true}
            >
              {t('continue-string')}
            </RoundedButton>
          </div>
          <Divider className='my-6' />
          <div className='mx-auto'>
            <BackBtn onClick={closePasswordStep} />
          </div>
        </div>
      </form>

      <AcceptTermsModal
        termsAndConditions={termsAndConditions}
        onAccept={handleAcceptTerms}
        onClose={() => dispatch(closeModal('accept-terms'))}
      />
    </FormProvider>
  )
}

export default LoginForm
